import React, { useEffect, useState } from "react";
import Tiles from "../InteractiveTiles";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import supportInsightsIcon from "../../assets/northstar/support_insights.png";
import axios from "axios";

const InsightsComponent: React.FC = () => {
  const { accessToken, clearIdentity, impersonation } = useAuthContext();
  const [files, setFiles] = useState([]);

  const { data: newFiles, error: filesError } = useNorthstar(
    "/api/files?filters[documentType][$eq]=Insight&populate[0]=tags",
    accessToken,
    {
      method: "get",
    },
  );

  useEffect(() => {
    if (!newFiles) return;
    setFiles(newFiles.data);
  }, [JSON.stringify(newFiles)]);

  useEffect(() => {
    let ignore = false;

    if (filesError?.status === 401 && !ignore) clearIdentity();

    return () => {
      ignore = true;
    };
  }, [filesError?.status]);

  const tags = (tagData) => {
    const output = [];

    for (const tag of tagData) {
      output.push({
        id: tag.id,
        name: tag.attributes.name,
      });
    }

    return output;
  };

  return (
    <Tiles
      items={files.map((file) => {
        return {
          id: file.attributes.uuid,
          key: file.attributes.uuid,
          name: file.attributes.displayName || file.attributes.name,
          description: file.attributes.description,
          thumbnail: supportInsightsIcon,
          tags: tags(file.attributes.tags.data),
          size: file.attributes.size,
          path: null,
          isDownloadable: file.attributes.isDownloadable,
          ext: file.attributes.ext,
        };
      })}
    />
  );
};

export default InsightsComponent;
