import { useState, useEffect } from "react";
import axios, { isAxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { useAuthContext } from "../contexts/authContext";

export const request = (
  endpoint: string,
  token: string,
  config: AxiosRequestConfig,
): Promise<AxiosResponse> => {
  const resolvedConfiguration = {
    ...config,
    url: new URL(endpoint, new URL(process.env.GATSBY_API_URL)).href,
    headers: {
      ...(config?.headers ?? {}),
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(resolvedConfiguration);
};

export default function useNorthstar(
  endpoint: string,
  accessToken?: string,
  options?: AxiosRequestConfig,
  onSuccessCallback: { (): void } | null = null,
  onErrorCallback: { (): void } | null = null,
  baseURL: string = process.env.GATSBY_API_URL,
) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosResponse>(null);

  const { impersonation: impersonate } = useAuthContext();

  const fetchData = (
    updatedEndpoint: string = endpoint,
    ignore: boolean = false,
  ) => {
    const fetch = async () => {
      setIsLoading(true);
      const url = new URL(updatedEndpoint, new URL(baseURL)).href;

      options.headers = {
        ...(options?.headers || {}),
        "northstar-authorization": `Bearer ${accessToken}`,
      };

      if (impersonate) {
        options.headers["northstar-impersonate"] = impersonate;
      }

      try {
        const { data } = await axios(url, options);

        if (onSuccessCallback) onSuccessCallback();

        return { data, error: null };
      } catch (err) {
        if (!isAxiosError(err)) throw err;

        if (onErrorCallback) onErrorCallback();

        return {
          data: null,
          error: err.response,
        };
      }
    };

    fetch().then((v) => {
      if (!ignore) {
        setData(v.data);
        setError(v.error);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    let ignore = false;

    if (!accessToken) return;

    fetchData(endpoint, ignore);

    return () => {
      ignore = true;
    };
  }, [
    accessToken,
    impersonate,
    JSON.stringify(options?.params),
    options?.method,
    JSON.stringify(options?.data),
  ]);

  return { data, isLoading, error };
}
