import React from "react";
import InteractiveTile, { TileComponentProps } from "../InteractiveTile";

interface TilesProps {
  items: TileComponentProps[];
}

const InteractiveTiles: React.FC<TilesProps> = ({ items }) => {
  return (
    <div className="pb-6 flex flex-row flex-wrap flex-start">
      {items.map((item) => {
        return (
          <InteractiveTile
            id={item.id}
            name={item.name}
            description={item.description}
            tags={item.tags}
            thumbnail={item.thumbnail}
            size={item.size}
            isDownloadable={item.isDownloadable}
            ext={item.ext}
          />
        );
      })}
    </div>
  );
};

export default InteractiveTiles;
