import React from "react";

export enum TagStyle {
  ROUND = "px-3 py-1 rounded-full text-white font-sans font-semibold text-xs w-max mr-1",
  SQUARE = "px-3 py-1.5 text-xs font-semibold tracking-wide border border-orange text-orange",
}

export enum PillColor {
  ORANGE = "bg-orange",
  BLUE = "bg-blue",
}

const Tag: React.FC<{
  id?: number | string;
  name: string;
  tagStyle?: TagStyle;
  pillColor?: PillColor;
}> = ({
  id,
  name,
  tagStyle = TagStyle.ROUND,
  pillColor = PillColor.ORANGE,
}) => {
  return (
    <div
      key={`${id}:${name}`}
      className={`${tagStyle} ${tagStyle == TagStyle.ROUND && pillColor}`}
    >
      {name}
    </div>
  );
};

export default Tag;
