import React, { useState } from "react";
import Tag, { PillColor } from "../Tag";
import { IDocumentTag } from "../../types/documents";
import {
  FolderArrowDownIcon,
  FolderOpenIcon,
} from "@heroicons/react/24/outline";
import { navigate } from "gatsby";
import axios from "axios";
import { useAuthContext } from "../../contexts/authContext";

const byteSize = require("byte-size");

export interface TileComponentProps {
  id: string;
  name: string;
  description?: string | null;
  tags?: IDocumentTag[];
  thumbnail: string;
  size?: number;
  isDownloadable?: string;
  ext: string;
}

const TileComponent: React.FC<TileComponentProps> = ({
  id,
  name,
  description = null,
  tags = null,
  thumbnail,
  size = null,
  isDownloadable = null,
  ext,
}) => {
  const { accessToken } = useAuthContext();

  const [isHovered, setIsHovered] = useState(false);

  const byteString = (size: number) => {
    const bytes = byteSize(size);
    return `${bytes.value} ${bytes.unit}`;
  };

  const shouldRenderFile = () => {
    return ["txt", "md", "pdf"].includes(ext);
  };

  return (
    <>
      <div
        className="w-[300px] border-gray-light border rounded-3xl mr-9 mb-8 bg-white shadow-lg"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        key={name}
      >
        <div className="p-4 bg-blue min-h-[167px] flex justify-center items-center rounded-t-3xl">
          <img
            src={thumbnail}
            alt="img"
            className="object-scale-down h-[70px]"
          ></img>
        </div>
        {!isHovered ? (
          <div className="p-4 min-h-[180px] flex flex-col">
            <div className="font-bold line-clamp-1">{name}</div>
            {description !== "" ? (
              <div className="line-clamp-3 mt-1">{description}</div>
            ) : (
              <></>
            )}
            <div className="grow"></div>
            <div className="flex flex-wrap justify-left my-2 gap-1">
              {tags &&
                tags
                  .sort((a, b) => (a.name < b.name ? -1 : 1))
                  .map((tag) => (
                    <Tag
                      id={tag.id}
                      name={tag.attributes?.name || tag.name}
                      pillColor={PillColor.ORANGE}
                    />
                  ))}
            </div>
          </div>
        ) : (
          <div className="p-4 min-h-[180px] flex flex-col justify-center">
            <div className="font-bold line-clamp-1">{name}</div>
            <div className="flex justify-center w-full m-auto mt-4">
              <div
                className={`mr-5 flex flex-col items-center ${
                  shouldRenderFile()
                    ? "cursor-pointer text-blue hover:text-orange"
                    : "text-gray-400"
                }`}
                onClick={() => {
                  shouldRenderFile() && navigate(`/insights/${id}`);
                }}
              >
                <FolderOpenIcon className="h-16" />
                <div className="text-sm">Open</div>
              </div>
              <a
                className={`flex flex-col items-center ${
                  isDownloadable
                    ? "cursor-pointer text-blue hover:text-orange"
                    : "text-gray-400 cursor-default pointer-events-none"
                }`}
                href={`${process.env.GATSBY_API_URL}/api/files/${id}/open`}
                download
              >
                <FolderArrowDownIcon className={`h-16`} />
                <div className="flex">
                  <div className="text-sm">Download</div>
                  {size && (
                    <div className="text-sm ml-1">({byteString(size)})</div>
                  )}
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TileComponent;
