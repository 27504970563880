import React from "react";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import InsightsComponent from "../../components/Insights";
import BannerComponent from "../../components/Banner";

const Insights: React.FC = () => {
  return (
    <>
      <NorthstarPageLayout title="Insights">
        <>
          <BannerComponent
            title="Insights"
            description="Insights provide access to curated content and playbooks from
            experts on our Data Science, Go-to-Market and Talent teams."
          />
          <InsightsComponent />
        </>
      </NorthstarPageLayout>
    </>
  );
};

export default Insights;
